<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-04 16:36:36
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-06 18:44:51
-->
<template>
  <div class="about" v-loading="loading">
    <div v-if="hasArtContent && artDetail.publishStatus == 1">
      <List
        class="listStyle"
        :data="listdata"
        :articleName="artDetail.articleName"
        :defaultGroup="defaultGroup"
        :customGroup="customGroup"
        @artChange="artChange"
        v-if="usageStyleId == 2"
      />
      <Breadcrumb :data="Breadcrumb" v-else />
      <div class="art-container">
        <div class="content">
          <div class="title" id="arttitle" v-show="!loading">{{ artDetail.articleName || $t("Detail.noName") }}</div>
          <div class="desc" id="artdesc">{{ artDetail.articleDescribe }}</div>
          <AuthorInfo :AuthorInfo="AuthorInfo" />
          <!-- 为了防止移动端弹出键盘 加入v-if销毁组件 -->
          <Editor class="editor" v-if="html" v-model="html" style="min-height: 305px" :defaultConfig="editorConfig" @onCreated="editorOnCreated"></Editor>
        </div>
        <Catalogue ref="cataRef" :treeResult="treeResult" :hidden="hidden" :remarkHeight="remarkHeight" :articleName="artDetail.articleName" />
      </div>
    </div>
    <Error style="height: 72vh" :remark="$t('error.EmptyTip')" v-else />
  </div>
</template>
<script>
import Breadcrumb from "./module/Breadcrumb.vue";
import { SlateNode } from "@wangeditor/editor";
import Catalogue from "./module/Catalogue.vue";
import List from "./module/List.vue";
import { Editor } from "@wangeditor/editor-for-vue";
import AuthorInfo from "./module/Author.vue";
import Error from "./Error.vue";
export default {
  components: {
    Editor,
    List,
    Catalogue,
    Breadcrumb,
    AuthorInfo,
    Error,
  },
  data() {
    return {
      expand: false,
      editor: null,
      hasArtContent: true,
      html: "",
      editorConfig: {
        placeholder: "",
        readOnly: true,
        autoFocus: false,
        scroll: false,
        MENU_CONF: {},
      },
      artInfo: {},
      loading: false,
      treeResult: [],
      listdata: {},
      customGroup: {},
      defaultGroup: {},
      hidden: true,
      remarkHeight: "",
      articleName: "",
      artDetail: {
        publishStatus: 1,
      },
      AuthorInfo: {
        updateMemberHeadUrl: "",
        updateMemberName: "",
        updateTime: "",
      },
      Breadcrumb: [],
    };
  },
  mounted() {
    this.initData();
    this.remarkHeight = document.getElementById("artdesc").offsetHeight;
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scroll);
  },
  computed: {
    winWidth: function () {
      return this.$store.state.winWidth;
    },
    language: function () {
      return this.$store.state.defaultlanguage;
    },
    usageStyleId: function () {
      return this.$store.state.globalConfig.usageStyleId;
    },
    usageManageId() {
      return this.$store.state.defaultlanDetail.usageManageId;
    },
  },
  watch: {
    language() {
      this.initData();
    },
    $route() {
      this.initData();
    },
  },

  methods: {
    //初始化数据
    initData() {
      this.hasArtContent = true;
      let { articleId, usageCategoryId, usageGroupId } = this.$route.query;
      let wikiArticleNavParams = {
        articleId,
        usageManageId: this.usageManageId,
        language: this.language,
        usageCategoryId,
        usageGroupId,
      };
      let usageDetailparams = {
        usageCategoryId,
        categoryLanguage: this.language,
      };
      this.loading = true;
      Promise.all([this.$api.wikiArticleNav(wikiArticleNavParams), this.$api.getUsageDetail(usageDetailparams)])
        .then((result) => {
          if (result[0].code == 200 && result[1].code == 200) {
            this.getArticleDetail(result[0]);
            this.getUsageDetail(result[1]);
          } else {
            this.hasArtContent = false;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    editorOnCreated(editor) {
      this.editor = Object.seal(editor);
      this.onChange(editor);
    },
    artChange(data, usageGroupId) {
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: {
          articleId: data,
          usageGroupId,
          usageCategoryId: this.$route.query.usageCategoryId,
        },
      });
      this.getArticleDetail();
    },

    wikiArticleNav(res) {
      if (res.data.groupName == null) {
        this.Breadcrumb = [
          {
            name: res.data.categoryName,
            Name: "Collections",
            params: this.$router.params,
            query: this.$route.query.usageCategoryId,
          },
          {
            name: this.articleName,
          },
        ];
      } else {
        this.Breadcrumb = [
          {
            name: res.data.categoryName,
            Name: "Collections",
            params: this.$router.params,
            query: this.$route.query.usageCategoryId,
          },
          {
            name: res.data.groupName,
            Name: "Collections",
            params: this.$router.params,
            query: this.$route.query.usageCategoryId,
          },
          {
            name: this.articleName,
          },
        ];
      }
    },
    getUsageDetail(res) {
      this.listdata = res.data;
      this.customGroup = res.data.customGroup;
      this.defaultGroup = res.data.defaultGroup;
    },
    onChange(editor) {
      const headers = editor.getElemsByTypePrefix("header");
      if (headers.length > 0) {
        this.treeResult = [];
        const observer = new IntersectionObserver(this.visibleChnage, {
          rootMargin: "-90px 0px 0px 0px",
        });
        const headerList = headers.map((header) => {
          return {
            id: header.id,
            type: header.type,
            text: SlateNode.string(header),
          };
        });
        Array.from(headerList, (item) => {
          const element = document.getElementById(item.id);
          observer.observe(element);
        });
        this.createPid(headerList);
        this.getChildren(headerList, this.treeResult, null);
      } else {
        this.treeResult = [];
      }
    },
    getChildren(data, result, pid) {
      for (const item of data) {
        if (item.pid === pid) {
          const newItem = { ...item, children: [] };
          result.push(newItem);
          this.getChildren(data, newItem.children, item.id);
        }
      }
    },
    visibleChnage(entries) {
      let observe = entries[0];
      const id = observe.target.getAttribute("id");
      if (observe.isIntersecting) {
        this.activeTocId = id;
        this.$refs.cataRef.setCurrentKey(id);
        return;
      }
    },
    createPid(headerList) {
      headerList.reduce((pre, current, index) => {
        // 首次循环 需要覆值
        if (index == 1) pre.pid = null;
        // 修改
        if (pre.type < current.type) {
          current.pid = pre.id;
        } else if (pre.type == current.type) {
          current.pid = pre.pid;
        } else {
          current.pid = null;
        }
        return current;
      });
    },
    scroll() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      if (scrollStep < 0) {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    },
    getArticleDetail(navDetail) {
      this.artInfo = this.$route.query.articleId;
      this.html = "";
      this.$api
        .getArticleDetail({
          articleId: this.artInfo,
          language: this.language,
          type: "publish",
        })
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.artDetail = res.data;
            this.articleName = res.data.articleName;
            this.AuthorInfo.updateMemberHeadUrl = res.data.updateMemberHeadUrl;
            this.AuthorInfo.updateTime = res.data.updateTime;
            this.AuthorInfo.updateMemberName = res.data.updateMemberName;
            this.authorInfo = {
              updateMemberHeadUrl: res.data.updateMemberHeadUrl,
              updateMemberName: res.data.updateMemberName,
              updateTime: res.data.updateTime,
            };
            this.wikiArticleNav(navDetail);
            this.html = res.data.articleContent || "<p><br></p>";

            if (!this.articleName && !this.artDetail.articleContent && !this.artDetail.articleDescribe) this.hasArtContent = false;
          } else {
            this.artDetail.publishStatus = 0;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  beforeDestroy() {
    window.addEventListener("scroll", this.scroll, false);
    if (this.editor == null) return;
    this.editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.w-e-text-container .w-e-scroll) {
  font-size: 0.4103rem;
}
.about {
  .art-container {
    background-color: #fff;
    padding-top: 0.641rem;

    margin-left: 0.5128rem;
    margin-right: 0.5128rem;
    border-radius: 0.1538rem;
  }
}

.last {
  color: rgba(0, 0, 0, 0.8);
}
span {
  width: 0.4103rem;
  height: 0.4103rem;
  display: inline-block;
  margin: 0 0.1026rem;
}

.content {
  position: relative;
  padding: 0 0.5128rem 0.5128rem;
  min-height: 64.6vh;

  .title {
    font-family: PingFangSC-Semibold;
    font-size: 0.7692rem;
    line-height: 1.3;
    color: #000000;
    letter-spacing: 0;
    font-weight: 600;
  }
  .desc {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    margin-top: 0.3077rem;
    margin-bottom: 0.6154rem;
    text-align: left;
  }
  .hidden {
    display: none;
  }
  .block {
    display: block;
  }
}
.breadcrumb {
  padding: 0.6205rem 0.625rem 0;
}
.listStyle {
  margin-bottom: 0.4103rem;
}
.editor {
  margin-top: 0.3154rem;
}
</style>
