<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-07 10:31:52
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2022-12-14 10:37:07
-->
<template>
  <div class="error_main">
    <Error class="error" :remark="remark || $t('error.ErrorTip')" />
  </div>
</template>

<script>
import Error from "./module/Errormobile.vue";

export default {
  props: {
    remark: String,
  },
  components: { Error },
  data() {
    return {
      expand: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.error_main {
  display: flex;
  height: 100vh;
  flex-direction: column;
  .error {
    flex: 1;
  }
}
</style>
