<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-04 09:22:51
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-03 18:37:49
-->
<template>
  <div class="Author" v-show="AuthorInfo.updateMemberName">
    <img class="avatar" :src="AuthorInfo.updateMemberHeadUrl" alt srcset />
    <div class="user-info">
      <div class="user-name">
        <span class="lable">{{ $t("GroupList.Author.RecentUpdate") }}</span>
        <span class="name">{{ AuthorInfo.updateMemberName }}</span>
      </div>
      <div class="lastupdateTime">{{ fomateDateTime }}</div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "Author",
  props: {
    AuthorInfo: {
      type: Object,
      defalut: () => {},
    },
  },
  created() {},
  mounted() {},
  computed: {
    fomateDateTime() {
      return dayjs(this.AuthorInfo.updateTime).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
<style scoped lang="scss">
.Author {
  display: flex;
  .avatar {
    width: 0.8205rem;
    height: 0.8205rem;
    margin-right: 0.3077rem;
    border-radius: 50%;
    img {
      width: 100%;
    }
  }
  .user-info {
    font-size: 0.3333rem;
    .user-name {
      color: rgba(0, 0, 0, 0.6);
      line-height: 1.3;
    }
    .lastupdateTime {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
