<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-05 19:23:07
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 11:30:06
-->
<template>
  <div class="main">
    <div class="list" @click.prevent="isexpand">
      <div class="listleft">
        <svg-icon class="listicon" icon-class="art-list"></svg-icon>
        <div class="listdesc text-ellipsis">{{ articleName || $t("Detail.noName") }}</div>
      </div>
      <div class="listright">
        <!-- <img src="@/assets/arrow-right-mini-with-arrow@3x.png" alt /> -->
      </div>
    </div>
    <div class="core">
      <div class="art-content" :class="{ isClose: expand }">
        <div class="art-content-container">
          <div class="art-title content">{{ $t("Detail.Articlelist") }}</div>
          <div class="no-group">
            <div
              class="art-item content text-ellipsis"
              :class="{ activeLink: k.articleId == activeLink && (usageGroupId == -1 || !usageGroupId) }"
              v-for="k in defaultGroup.articles"
              :key="k.id"
              @click="handleActiveItem(k, defaultGroup.usageGroupId)"
            >
              {{ k.articleName || $t("Detail.noName") }}
            </div>
          </div>
          <div class="has-group">
            <template v-for="i in customGroup">
              <div class="group" v-if="i.articles.length > 0" :key="i.id">
                <div class="group-title content text-ellipsis" @click="handleToggle(i)">
                  <span class="el-icon el-icon-caret-right" :class="{ expanded: activeCollapse == i }"></span>
                  <span>{{ i.usageGroupName }}</span>
                </div>
                <el-collapse-transition>
                  <div class="sub-art-list" v-show="activeCollapse == i">
                    <div
                      class="art-item content text-ellipsis sub-art-item"
                      v-for="j in i.articles"
                      :key="j.id"
                      :class="{ activeLink: j.articleId == activeLink && usageGroupId > 0 }"
                      @click="handleActiveItem(j, i.usageGroupId)"
                    >
                      {{ j.articleName || $t("Detail.noName") }}
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articleName: String,
    data: {},
    customGroup: {},
    defaultGroup: {},
  },
  data() {
    return {
      expand: false,
      activeLink: "",
      activeName: "",
      activeCollapse: "",
      usageGroupId: "-1",
    };
  },
  mounted() {
    this.activeLink = this.$route.query.articleId;
    this.usageGroupId = this.$route.query.usageGroupId;
  },
  beforeDestroy() {
    this.canScroll();
  },
  methods: {
    handleToggle(index) {
      if (index === this.activeCollapse) {
        this.activeCollapse = "";
      } else {
        this.activeCollapse = index;
      }
    },
    handleActiveItem(item, usageGroupId) {
      this.isexpand();
      this.activeLink = item.articleId;
      this.usageGroupId = usageGroupId;
      this.$emit("artChange", item.articleId, usageGroupId);
    },
    isexpand() {
      this.expand = !this.expand;
      if (document.querySelector(".listright").style.transform == "rotate(90deg)") {
        document.querySelector(".listright").style.transform = "rotate(0deg)";
      } else {
        document.querySelector(".listright").style.transform = "rotate(90deg)";
      }
      if (this.expand == true) {
        this.stopScroll();
      } else {
        this.canScroll();
      }
    },
    stopScroll() {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    },
    canScroll() {
      document.body.style.overflow = "";
      document.body.style.height = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding: 0 0.5128rem;
  padding-top: 0.3846rem;
  display: flex;
  align-items: center;
  height: 1.2308rem;
  justify-content: space-between;
  //   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .listleft {
    display: flex;
    align-items: center;
    font-size: 0.5641rem;
    .listicon {
      width: 1em;
      height: 1em;
    }
    .listdesc {
      width: 8.2051rem;
      line-height: 0.63rem;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0;
      margin-left: 0.2564rem;
    }
  }
  .listright {
    display: flex;
    align-items: center;
    img {
      width: 0.6154rem;
      height: 0.6154rem;
      transition: all 0.3s;
    }
  }
}
.art-title {
  padding: 0 0.5128rem;
  height: 1.0769rem;
  line-height: 1.0769rem;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0;
  font-weight: 400;
  //   background-color: #fff;
}
.art-item {
  // padding: 0 0.5128rem;
  padding-right: 0.5128rem;
  height: 1.0769rem;
  line-height: 1.0769rem;
  font-family: PingFangSC-Regular;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0;
  font-weight: 400;
  //   background-color: #fff;
  text-indent: 0.5128rem;
}
.sub-art-item {
  padding-left: 1.0256rem;
}
.art-item-list {
  text-indent: 0;
}
.group-title {
  line-height: 1.0769rem;
  padding: 0 0.5128rem;
  //   background-color: #fff;
  display: flex;
  align-items: center;
  span {
    font-family: PingFangSC-Regular;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0;
    font-weight: 400;
  }
}
.art-content {
  width: 100%;
  position: absolute;
  z-index: 1000;
  height: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.45);

  transition: all 0.3s;
  &.isClose {
    height: calc(100vh - 5.7rem);
  }

  .art-content-container {
    padding-bottom: 0.4rem;
    background-color: #f3f5f7;
    // background-color: #fff;
  }
}
.activeLink {
  color: var(--theme-color);
}
.el-icon {
  color: rgba(0, 0, 0, 0.2);
  font-size: 14px;
  margin-right: 10px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}
.expanded {
  transform: rotate(-90deg);
}
</style>
