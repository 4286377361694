<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-21 11:40:43
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2023-02-02 16:19:09
-->
<template>
  <div class="page404">
    <img class="icon" src="@/assets/empty.png" alt srcset />
    <div class="remark">{{remark}}</div>
  </div>
</template>
<script>
export default {
  props: {
    remark: String,
  },
  name: "Error",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 5.1538rem;
    height: 3.4103rem;
  }
  .remark {
    font-size: 0.4359rem;
    color: rgba($color: #000000, $alpha: 0.4);
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    margin-top: 1.2821rem;
  }
}
</style>
