<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-06 15:26:16
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-03 20:44:18
-->
<template>
  <div class="catalogue">
    <div class="title" v-show="hidden" @click="changStatus">
      <img class="img" src="../../../assets/contents-line@3x.png" alt />
      <span class="catalogue-name">{{ $t("Detail.Catalogue.Catalogue") }}</span>
    </div>
    <div class="content" @click.self="changStatus" v-show="!isShow">
      <div class="desc">
        <div class="topcontent" @click="() => {}">
          <div class="content_title">{{ articleName }}</div>
          <img class="img1" @click="changStatus" src="../../../assets/close-line-basis@3x.png" alt />
        </div>
        <div id="middlecontent">
          <el-tree
            class="navigation-tree"
            ref="treeDataRef"
            node-key="id"
            :data="treeResult"
            :props="defaultProps"
            :empty-text="$t('Detail.noData')"
            :highlight-current="true"
            current-node-key="w-e-element-4328"
            :defaultExpandAll="true"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    treeResult: [],
    hidden: {
      type: Boolean,
    },
    articleName: {},
  },
  data() {
    return {
      isShow: true,
      activeTocId: "",
      scrolling: false,
      defaultProps: {
        children: "children",
        label: "text",
      },
    };
  },
  methods: {
    changStatus() {
      this.isShow = !this.isShow;
      if (this.isShow == false) {
        this.stopScroll();
      } else {
        this.canScroll();
      }
    },
    setCurrentKey(id) {
      this.$refs.treeDataRef.setCurrentKey(id);
    },
    stopScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false);
    },
    canScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", mo, false);
    },
    handleNodeClick(item) {
      let anchorElement = document.getElementById(item.id);
      let titleHeight = document.getElementById("arttitle").offsetHeight;
      let remarkHeight = document.getElementById("artdesc").offsetHeight;
      clearTimeout(this.scrolling);
      this.scrolling = setTimeout(() => {
        this.scrolling = null;
      }, 1500);
      this.$emit("getvalue1", this.scrolling);
      window.scrollTo({
        left: 0,
        top: anchorElement.offsetTop + remarkHeight + titleHeight + 72 + 30, // 可以根据滚动情况增减数值
        behavior: "smooth",
      });
      this.isShow = !this.isShow;
      if (this.isShow == false) {
        this.stopScroll();
      } else {
        this.canScroll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.catalogue {
  .title {
    position: fixed;
    right: 0.5128rem;
    bottom: 1.1282rem;
    width: 1.641rem;
    height: 1.641rem;
    background-color: #fff;
    border-radius: 0.8205rem;
    border: 0.33px solid rgba(0, 0, 0, 0);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0;
    font-weight: 400;
    .img {
      width: 0.4103rem;
      height: 0.4615rem;
      margin-left: 0.6154rem;
      margin-top: 0.4103rem;
    }
    span {
      margin-top: 0.05rem;
      display: block;
      text-align: center;
    }
  }
  .content {
    position: fixed;
    z-index: 12;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.4);
    .desc {
      height: calc(100% - 4rem);
      margin-top: 4rem;
      padding: 0 0.5128rem;
      background-color: #fff;
      overflow: auto;
      border-radius: 20px 20px 0 0;
      animation: all 0.5s;
      @keyframes all {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(0);
        }
      }
      .topcontent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 1.0256rem;
        line-height: 1.0256rem;
        margin: 0.5128rem 0;
        .img1 {
          align-items: center;
          width: 0.5128rem;
          height: 0.5128rem;
        }
        .content_title {
          width: 6.1538rem;
          margin-top: 0.1rem;
          font-size: 18px;
          font-family: PingFangSC-Regular;
          overflow: hidden;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .navigation-tree {
    // width: 8.5rem;
    ::v-deep {
      .el-tree-node__label {
        width: 260px;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
      }
      .el-tree-node__content {
        padding: 4px 0;
      }
      .is-current > .el-tree-node__content {
        border-left: 2px solid var(--theme-color);
      }
    }
  }
}
</style>
